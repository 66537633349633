export const useBranchStore = defineStore('branchesStore', {
    state: () => ({
      Branches: '',
      SelectedBranchIdx: 0,
      SelectedBranchId: '',
      BranchChanged: false,
    }),
    actions: {
      async fetch() {
        const res = await $fetch('/api/w/branches', {
          timeout: 10000
        });

        this.Branches = res;
        if (!this.SelectedBranchId) {
          this.SelectedBranchId = this.Branches[this.SelectedBranchIdx]["branchId"]
        }
      },
      updateSelectedBranch(idx) {
        this.SelectedBranchIdx = idx
        this.SelectedBranchId = this.Branches[idx]["branchId"]
      },
      updateSelectedBranchId(id) {
        this.SelectedBranchId = id
      },
      currentBranch() {
        return this.Branches[this.SelectedBranchIdx]
      },
      async findClosestBranch(lat, lng) {
        const res = await $fetch('/api/w/branches/zone/search', {
          method: 'POST',
          headers: {
            'X-Brownie-Branch-ID': this.SelectedBranchId
          },
          body: {lat: lat, lng: lng},
          timeout: 10000
        });

        for (let br of this.Branches) {
          let bid = br.branchId
          if (res.zones[bid]) {
            br.aRemarks = "in-range"
          }
        }

        return res

      }
    }
  })