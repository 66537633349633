export const useMenuStore = defineStore('menuStore', {
    state: () => ({
      pending: true,
      categories: [],
      menu: null,
      options: { flavors: null, milkTypes: null },
      promos: [],
      textPromos: [],
      config: {},
      version: '',
      flaggedCategories: {},
    }),
    actions: {
      async fetch() {
        const res = await $fetch('/api/w/fullMenu');
        this.categories = res.categories
        this.menu = res.menu
        this.promos = res.promos
        
        this.pending = false
      },
      async fetchForBranchId(branchId) {
        console.log('branch id in pinia ' + branchId)
        const res = await $fetch('/api/w/fullMenu', {
            headers: {
                'X-Brownie-Branch-ID': branchId
            }
        });

        this.categories = res.categories
        this.menu = res.menu
        this.promos = res.promos
        this.options.flavors = res.flavors
        this.options.milkTypes = res.milkTypes
      
        this.textPromos = this.promos.filter((promo) => {
          return !promo['image']
        });

        this.version = res.version

        this.pending = false
      },
      async fetchStaticMenu(branchId) {
        console.log('branch id in pinia ' + branchId)
        const res = await $fetch('/api/w/staticMenu', {
            headers: {
                'X-Brownie-Branch-ID': branchId
            }
        });

        this.categories = res.categories
        this.menu = res.menu
        this.promos = res.promos
        this.options.flavors = res.flavors
        this.options.milkTypes = res.milkTypes
        //this.config = res.config
      
        this.textPromos = this.promos ? this.promos.filter((promo) => {
          return !promo['image']
        }) : []

        this.version = res.version

        this.pending = false
      },
      async fetchConfig(branchId) {
        console.log('branch id in pinia ' + branchId)
        const res = await $fetch('/api/w/storeConfig', {
            headers: {
                'X-Brownie-Branch-ID': branchId
            }
        });

        this.config = res

        this.pending = false
      },
    }
  })