<script setup lang="ts">
import { preProcessFile } from 'typescript';


const emits = defineEmits(['back', 'goToLogin'])
const props = defineProps(['isMobile', 'order', 'tracking'])

const cartData = useCartStore()
const branchesData = useBranchStore()
const sharedData = useSharedStore()

import { computed } from 'vue';
import { useStorage, useTimeoutFn } from "@vueuse/core";
const orderStore = useStorage("LastOrder", {});

const { init, notify, close, closeAll } = useToast();

const rating = ref(0)

const ratingEnabled = ref(true)

const loading = ref(true)

const order = ref()

const orderProgress = ref(0)

const pollError = ref(false)

const status = ref()

const statusCode = ref()

const orderConfirmed = ref(false)

let shouldReloadPresenter = false


function formatOptions(item) {
    //  let str = ""
    //  for (let option of item.options) {
    //      str += `+${option.name} `
    //  }
    /*
         routes: [
          { 
            route: "/api*",
            methods: ["GET", "POST", "PUT", "DELETE"]
          },
          { 
            route: "/api/w/*",
            methods: ["GET", "POST", "PUT", "DELETE"],
            rewrite: "/api/server"
          }
        ]
    */
    //  return str
 //   console.log('options' + item.options)
    if (item.options) {
        return item.options.join(', ')
    } 
    return ''
    
}

function orderTypeStr(type) {
    return ({'in-store': 'In-Store', 'pickup': 'Pick up', 'delivery': 'Delivery'})[type]
}



const { isActive: pollTimerActive, resume: startPollTimer, pause: stopPollTimer } = useIntervalFn(async () => {
    console.log('timer activated')
    await pollOrder()
}, 10000, { immediate: false })



let isIdle

if (props.tracking) {
    isIdle = computed({
        get() { return sharedData.isIdle },
        set(val) {}
    })
    watch(isIdle, async (idleValue) => {
        if (idleValue) {
            stopPollTimer()
        } else {
            if (!pollTimerActive.value && !orderConfirmed.value) {
                await pollOrder()
                startPollTimer()
            }
        }
        console.log(`isIdle ${idleValue}`)
    })
} else {
    isIdle = sharedData.isIdle
}

// onMounted(() => {
//     startPollTimer()
// })

// const timer = ref(10)
// let interval = null

// function resetTimer() {
//     timer.value = 10
//     setTimer()
// }

// function setTimer() {
//     interval = setInterval(() => {
//             if (timer.value === 0) {
//                 clearInterval(interval) 
//                 pollOrder()               
//             } else {
//                 timer.value--
//                 console.log(timer.value)
//             }             
//         }, 1000)
// }

async function loadOrder() {
    try {
        loading.value = true
        const trackedOrder = await $fetch('/api/w/order/latest', {
            method: 'GET',
            headers: {
                'X-Brownie-Branch-ID': branchesData.SelectedBranchId,
            },
            timeout: 10000,
        })

        loading.value = false
      
        order.value = trackedOrder
        orderStore.value.orderInfo = trackedOrder

        //resetTimer()
        startPollTimer()
        updateProgress()

    } catch (error) {
        loading.value = false
        // show error
        console.log('baaa' + error)
        if (error.statusCode == 401) {
            console.log('strager..')
            //clearInterval(interval)
            stopPollTimer()
            emits('goToLogin')
        } else if (error.statusCode == 404) {
            statusCode.value = 404
            orderStore.value = {}
            sharedData.ShowOrderTrackingBanner(false)
        }
    }
}

async function pollOrder() {
    try {
        console.log('polling order')
        const trackedOrder = await $fetch(`/api/w/order/poll`, { //${order.value.publicId}/
            method: 'GET',
            headers: {
                'X-Brownie-Branch-ID': branchesData.SelectedBranchId,
            },
            timeout: 10000,
        })

        console.log('order res')
        console.log(trackedOrder)
        pollError.value = false
        
        if (trackedOrder.accepted != null) { 
            order.value.accepted = orderStore.value.orderInfo.accepted = trackedOrder.accepted
            order.value.confirmed = orderStore.value.orderInfo.confirmed = trackedOrder.confirmed
        } else {
            status.value = orderStore.value.orderInfo.status = trackedOrder.status   
        }
        // resetTimer()
        updateProgress()

    } catch (error) {
        pollError.value = true
        // resetTimer()
        // show error
        console.log('baaa' + error)
        if (error.statusCode == 401) {
            console.log('stranger..')
            emits('goToLogin')
        }
    }
}

function updateProgress() {
    //orderProgress.value = order.value.accepted ? 25 : 10
    if (orderStore.value.orderInfo.type == 'in-store') {
        if (status.value == "accepted") {
            orderProgress.value = 50    
        } else if (status.value == "confirmed") {
            orderProgress.value = 100 
            orderStore.value.orderInfo.confirmed = true
            orderConfirmed.value = true  
            stopPollTimer() 
        } else {
            orderProgress.value = 10    
        }
    }
    
}

let stamps = 0
let rewards = 0

if (process.client) {
// onMounted(async () => {
    // await nextTick();
    console.log('mounted')
    if (!props.tracking) {
        console.log('!tracking')
        order.value = props.order
        loading.value = false
    } else {
        if (!orderStore.value.orderInfo) {
            await loadOrder()
        } else {
            order.value = orderStore.value.orderInfo
            // await pollOrder()
            startPollTimer()
            loading.value = false
        }
    }

    if (order.value) {
        calcLoyalty()
    }
    
// })
}

let totalWithRewards = 0

function calcLoyalty() {
    for (let item of order.value.items) {
            if (item.progressEligibleCard) 
                stamps++
            if (item.rewardEligibleCard)
                rewards++

            // if (item.quantity == 1) {
            //     if (!item.rewardEligibleCard) {
            //         totalWithRewards += item.subtotal
            //     }
            // } else {
            //     if (item.rewardEligibleCard) {
            //         totalWithRewards += item.subtotal //
            //     }
            // }
    }
}

async function rateOrder() {
    try {
        loading.value = true
        let orderId = props.order ? props.order.publicId : orderStore.value.orderInfo.publicId
        const newOrder = await $fetch(`/api/w/profile/orders/${orderId}/rating`, {
            method: 'PUT',
            headers: {
                'X-Brownie-Branch-ID': branchesData.SelectedBranchId
            },
            body: { numRating: rating.value },
            timeout: 10000,
        })

        loading.value = false
        ratingEnabled.value = false
      
        notify(`Thank you!`);
        shouldReloadPresenter = true

        
    } catch (error) {
        console.log('baaa' + error)
        loading.value = false
        if (error.statusCode == 401) {
            console.log('strager..')
           // emits('goToLogin') orderTrackingBanner
        }
    }
}

function goBack() {
    // if (props.tracking || interval) {
    //     clearInterval(interval)
    // }
   // if (pollTimerActive.value) {
    stopPollTimer()
   // }
    if (orderConfirmed.value && props.tracking) {
        orderStore.value = {}
        sharedData.ShowOrderTrackingBanner(false)
    }
    emits('back', shouldReloadPresenter)
}

function orderEligibleForRating() {
    if (order.value) {
        if (!order.value.confirmed) {
            return false
        }
        return !order.value.rating || order.value.rating == 0
    }
    return false
}

</script>

<template>
    <div class="flex flex-col place-content-center place-items-center w-full h-full"  >
        
        <h4 class="va-h4">Order</h4>

        <span v-if="loading" >loading...</span>

        <span v-if="statusCode == 404" class="mb-3">Cannot track the order. Please check the status from the orders page.</span>
        
        <div v-if="order" class="flex flex-col place-content-center place-items-center w-full" >
            <span class="text-md mb-3">{{ orderTypeStr(order.type) }}</span>

            <div v-if="props.tracking" class="flex flex-col place-content-center place-items-center mb-5">
                
                <div class="flex flex-row xs:mb-5">
                    
                    <VaProgressCircle indeterminate :size="25" thickness="0.6" v-if="(!sharedData.isIdle && !orderConfirmed)"/>
                    <span v-if="!pollError" class="text-md ml-1" style="color: green;"> {{ order.status ?? "New" }} </span>
                    <span v-else class="text-md ml-1" style="color: red;"> error tracking order. Trying again. Please check your internet connection </span>
                </div>

                
                <VaProgressBar 
                    size="large"
                    :model-value="orderProgress"
                    color="accent1"
                    class="min-w-[200px]"
                />
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 grid-flow-row md:grid-flow-col w-full">
                <div class="flex flex-col gap-1">
                    <span>
                        Date: {{ (new Date(order.date)).toLocaleString('en-JO') }}
                    </span>
                    <div class="font-light">
                        Branch: {{ order.branch.name }}    
                    </div>
                    <div class="front-light">
                        Address: {{ order.deliveryAddress ? order.deliveryAddress.addressName : 'ss' }}    
                    </div>
                </div>
                <div class="flex flex-col gap-1 items-start sm:items-end">
                    <VaBadge
                    class="mt-1"
                    style="--va-badge-font-size: 12px;"
                    :text="order.confirmed ? 'completed' : 'in progress'"
                    :color="order.confirmed ? 'primary' : 'accent1'" />
                    <VaBadge
                    class="mt-1"
                    style="--va-badge-font-size: 12px;"
                    :text="order.paymentMethod"
                    :color="'primary'" />
                    
                    <div>
                        <span v-if="order.loyaltyProgress">
                            {{ (order.confirmed ? "You got " : "you'll get") }} {{ `${stamps} ${stamps > 1 ? 'stamps' : 'stamp'}!` }}
                        </span>
                        <br/>
                        <span v-if="rewards">
                            🎉 {{ `You have ${rewards} free eligible ${rewards > 1 ? 'items' : 'items'}!` }}
                        </span>
                        <!-- <VaBadge
                        class="mt-1"
                        gradient
                        style="--va-badge-font-size: 12px; --va-badge-text-transform: lowercase;"
                        :text="`You gained ${order.loyaltyProgress} stamp(s)`"
                        :color="'success'" /> -->
                        
                    </div>
                </div>
            </div>
            
            <span class="mt-3 mb-2">
                Items:
            </span> 
            <div v-if="order" class="grid grid-cols-1 sm:grid-cols-2 gap-2 lg:grid-cols-3 w-full h-fit mb-6"> 
                
                <VaCard
                v-for="(item, index) in order.items"
                :key="index"
                :style="{ '--va-card-outlined-border': '2px solid var(--va-primary)', '--va-card-padding': '10px', 'cursor': 'pointer'}"
                outlined
                class="w-full h-fit"
                >
                
                <VaCardContent class="w-full h-fit">
                    <div class="flex flex-col  w-full">
                        <div class="grid grid-cols-6 w-full">
                            
                            <VaBadge 
                            class="h-fit"
                            color="primary" 
                            :text="item.quantity+'x'"  
                            style="--va-badge-font-size: 15px; --va-badge-text-transform: lowercase; --va-badge-text-wrapper-border-radius: 50%;" 
                            
                            />
                            
                            <div class="text-md font-bold w-fit col-span-3">
                                {{ item.rewardEligibleCard ? '🎁 ' : '' }}{{ item.item.name }}
                            </div>
                            
                            
                            <div class="flex flex-col content-end items-end w-full col-span-2">
                                <div class="text-md font-medium place-content-end place-items-end text-end">
                                    {{ item.subtotal }} JDs
                                </div>
                            </div>
                        </div>
                        
                        <div class="grid grid-cols-6 w-full">
                            <div class="col-start-2 col-span-4 text-sm">
                                {{ formatOptions(item) }}
                            </div>
                        </div>
                        
                        
                        
                    </div>
                    
                </VaCardContent>
            </VaCard>
        </div>
        
        <!-- <div class="flex flex-col"> -->
            <VaChip outline square class="mb-3 my-2 mx-2" readonly>
                Total:
                <span class="font-semibold mx-1 p-0.5">{{ order.total }} JDs</span>
            </VaChip>  


            <VaChip v-if="rewards > 0" outline square class="mb-1 mt-0 mx-2 text-center" color="accent1" readonly>
                Total is inclusive but, you'll only pay for the non-rewarded item
                <!-- <span class="font-semibold mx-1 p-0.5">{{ order.total }} JDs</span> -->
            </VaChip>  
        <!-- </div> -->

        
        
        
        
        <div class="shrink text-sm front-light">
            {{ order.publicId }}    
        </div>
        
    </div>
    
    <hr/>
    
    <div v-if="props.isMobile" class="mb-20"></div>
    
    
    <div class="flex flex-col border-t-2 border-l-2 border-r-2 border-dashed min-w-[280px]" v-if="!props.isMobile">
        <!-- <div class="text-sm front-light w-full text-center mt-1">
            Thank you!
        </div> -->
        
        <div class="flex flex-row w-full place-content-center place-items-center mt-2">
            <VaButton
            v-if="order && ((!order.rating || order.rating == 0) && ratingEnabled && orderEligibleForRating())"
            class="mb-2 mr-2"
            preset="primary"
            color="accent1"
            @click="rating = 0"
            icon="backspace"
            :disabled="rating == 0"
            />
            
            <VaRating
            v-if="orderEligibleForRating()"
            v-model="rating"
            class="mb-2"
            :texts="['Very Bad', 'Quite bad', 'Normal', 'Good', 'Excellent']"
            color="accent1"
            size="large"
            :halves="true"
            :readonly="order && ((order.rating && order.rating != 0) || !ratingEnabled)"
            :disabled="!ratingEnabled"
            />
            
        </div>
        
        <div class="flex flex-row">
            
            <VaButton
            preset="primary"
            class="grow mx-2 mb-2"
            @click="goBack"
            icon="arrow_back_ios"
            >
            Go back
        </VaButton>
        
        <VaButton
        v-if="orderEligibleForRating()"
        class="grow mx-2 mb-2"
        color="accent1"
        @click="rateOrder()"
        icon-right="grade"
        :disabled="rating == 0 || (order.rating && order.rating != 0) || !ratingEnabled"
        >
        {{ (order.rating && order.rating != 0) ? 'Rated' : 'Submit Rating'  }}
    </VaButton>
</div>
</div>


<div v-else class="flex flex-col border-t-2 border-l-2 border-r-2 border-dashed shadow-lg shadow-neutral-400 min-w-[280px]" style="position:fixed; bottom:0; background-color: var(--va-background-secondary); padding: 4px; padding-top: 8px;  border-color: var(--va-background-border); padding-bottom: env(safe-area-inset-bottom, 10px);">
    <!-- <div class="text-sm front-light w-full text-center mt-1">
        Thank you!
    </div>
    <VaChip outline square class="mb-3 mx-2 my-0.5">
        Total:
        <span class="font-semibold mx-1 p-0.5">{{ order.total }} JDs</span>
    </VaChip>           -->
    
    <div class="flex flex-row w-full place-content-center place-items-center mt-2">
        <VaButton
            v-if="(!order.rating || order.rating == 0) && ratingEnabled && orderEligibleForRating()"
            class="mb-2 mr-2"
            preset="primary"
            color="accent1"
            @click="rating = 0"
            icon="backspace"
            :disabled="rating == 0"
            />
            
            <VaRating
            v-if="orderEligibleForRating()"
            v-model="rating"
            class="mb-2"
            :texts="['Very Bad', 'Quite bad', 'Normal', 'Good', 'Excellent']"
            color="accent1"
            size="large"
            :halves="true"
            :readonly="(order.rating && order.rating != 0) || !ratingEnabled"
            />
            
        </div>
        
        <div class="flex flex-row">
            
            <VaButton
            preset="primary"
            class="grow mx-2 mb-2"
            @click="goBack"
            icon="arrow_back_ios"
            >
            Go back
        </VaButton>
        
        <VaButton
        v-if="orderEligibleForRating()"
        class="grow mx-2 mb-2"
        color="accent1"
        @click="rateOrder()"
        icon-right="grade"
        :disabled="rating == 0 || (order.rating && order.rating != 0) || !ratingEnabled"
        >
        {{ (order.rating && order.rating != 0) ? 'Rated' : 'Submit Rating'  }}
    </VaButton>
</div>
</div>
</div>
</template>

<style lang="scss">
// div {
//   flex-shrink: 0;
// }
</style>