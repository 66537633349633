import { default as indexbQN0ejW6ZeMeta } from "/opt/build/repo/pages/[id]/index.vue?macro=true";
import { default as linksF7uj5YC44rMeta } from "/opt/build/repo/pages/[id]/links.vue?macro=true";
import { default as errtestP4Acrg0vtNMeta } from "/opt/build/repo/pages/errtest.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as linkshQtzdrn8yvMeta } from "/opt/build/repo/pages/links.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as loyaltyyyyyyyyyyyyyyxm5gzB2FSEMeta } from "/opt/build/repo/pages/loyaltyyyyyyyyyyyyyy.vue?macro=true";
import { default as ordersVdL1KrUrvWMeta } from "/opt/build/repo/pages/orders.vue?macro=true";
import { default as profileNJNbyWXx9rMeta } from "/opt/build/repo/pages/profile.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: "id",
    path: "/:id()",
    component: () => import("/opt/build/repo/pages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "id-links",
    path: "/:id()/links",
    component: () => import("/opt/build/repo/pages/[id]/links.vue").then(m => m.default || m)
  },
  {
    name: "errtest",
    path: "/errtest",
    component: () => import("/opt/build/repo/pages/errtest.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    component: () => import("/opt/build/repo/pages/links.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "loyaltyyyyyyyyyyyyyy",
    path: "/loyaltyyyyyyyyyyyyyy",
    component: () => import("/opt/build/repo/pages/loyaltyyyyyyyyyyyyyy.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/opt/build/repo/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/opt/build/repo/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "menu-item",
    path: "/menu/:id",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "menu",
    path: "/menu",
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]