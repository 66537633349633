export const useSharedStore = defineStore('sharedStore', {
    state: () => ({
      BannerShown: false,//
      CartButtonShown: false,//
      screenIsMobile: false,
      screenIsTablet: false,
      screenIsMdUp: false,
      screenIsSmDown: false,
      orderTrackingBanner: false,
      isIdle: false,
      orderTypes: {'delivery': true, 'in-store': true, 'preorder': true },
      workingHours: '',
      workingDays: '',
      updateBannerShown: false,
      isOpen: true,
      isWorkingDay: true
    }),
    actions: {
        ShowBanner() {
        this.BannerShown = true;
      },
        HideBanner() {
        this.BannerShown = false;
      },
      ShowCartButton(shown) {
        this.CartButtonShown = shown;
      },
      ShowOrderTrackingBanner(show) {
        this.orderTrackingBanner = show
      },
      ShowUpdateBanner(shown) {
        this.updateBannerShown = shown;
      },
      SetIdle(idle) {
        this.isIdle = idle
      },
      SetIsOpen(open) {
        this.isOpen = open
      },
      SetIsWorkingDay(open) {
        this.isWorkingDay = open
      },
      SetDeliveryAvailable(avail) {
        this.orderTypes['delivery'] = avail
      },
      SetInStoreAvailable(avail) {
        this.orderTypes['in-store'] = avail
      },
      SetPreorderAvailable(avail) {
        this.orderTypes['preorder'] = avail
      },
      IsDeliveryAvailable() {
        return this.orderTypes['delivery']
      },
      IsInStoreAvailable() {
        return this.orderTypes['in-store']
      },
      IsPreorderAvailable() {
        return this.orderTypes['preorder']
      },
    },
  })