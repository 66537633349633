<script setup lang="ts">

import NotificationDropdown from '~/components/NotificationsDropdown.vue'
import OrderDetailsModal from '~/components/modals/OrderDetailsModal.vue';

const router = useRouter()

// const nav = [
//   { label: 'Menu', to: '/menu' },
//   { label: 'About', to: '/about' },
//   // { label: 'Parent (index)', to: '/parent' },
//   // { label: 'Parent (b)', to: '/parent/b' },
//   // { label: 'Keyed child', onClick: () => router.push(`/parent/reload-${(Math.random() * 100).toFixed()}`) },
//   // { label: 'Non-Keyed child', onClick: () => router.push(`/parent/static-${(Math.random() * 100).toFixed()}`) },
// ]

import { onBeforeUnmount, onMounted, watch } from "vue";
import { useBreakpoint } from "vuestic-ui";
import { useIntervalFn, useIdle } from '@vueuse/core'
import CheckoutModal from "~/components/modals/CheckoutModal.vue";

import { useStorage } from "@vueuse/core";

const orderStore = useStorage("LastOrder", {});

const breakpoints = useBreakpoint();

const branches = useBranchStore()
const sharedData = useSharedStore()
const menuData = useMenuStore()
const cart = useCartStore()

const page = ref()

//let showBanner = ref(shared.ShowBanner)
console.log('HERE 1')
//await callOnce(branches.fetch)

await callOnce('branches', async () => {
    //let bid = branchesData.Branches[branchIdx]['branchId']
    console.log('HERE 1 Once')
  //  await branches.fetch()
});

//const pollInterval = ref(2000)

const { idle, lastActive, reset } = useIdle(0.5 * 60 * 1000) 

const { isActive: pollTimerActive, resume: startPollTimer, pause: stopPollTimer } = useIntervalFn(async () => {
    console.log('timer activated')
    await pollBatch()
}, 8000, { immediate: false })

watch(idle, async (idleValue, oldVal) => {
  if (idleValue) {
    console.log(`idle, stop polling`)
    stopPollTimer()
  } else {
    console.log(`resume polling`)
    if (!pollTimerActive.value) {
     // startPollTimer()
    }
    if (idleValue != oldVal) {
      //await pollBatch()
    }
  }
  sharedData.SetIdle(idleValue)
})

const onResize = () => {
  sharedData.screenIsMobile = breakpoints.xs;
  sharedData.screenIsTablet = breakpoints.mdDown;
  sharedData.screenIsMdUp = breakpoints.mdUp;
  sharedData.screenIsSmDown = breakpoints.smDown;
};

onMounted(async () => {
  window.addEventListener("resize", onResize);
  onResize();
  // if (orderStore.value.orderId) {
  //   sharedData.orderTrackingBanner = true
  // }
  // refreshCookie('session')
  // sharedData.orderTrackingBanner = orderStore.value.orderId && useCookie('session').value ? true : false
  // if (!useCookie('session').value) {
  //   orderStore.value = {}
  // }
  //await pollBatch()
//  startPollTimer()
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
  console.log('unmounted')
  stopPollTimer()
});

const showAuthModal = ref(false)

const showOrderTrackModal = ref(false)

async function goToLogin(){
  showOrderTrackModal.value = false
  await navigateTo('/login');
}


const presentOrderDetailsModal = async () => {
  refreshCookie('session')
  if (useCookie('session').value)
    showOrderTrackModal.value = true
  else
    await goToLogin()
  // check if there's order id and set it
}


const goToLoyalty = async () => { //replace with a generic func
  refreshCookie('session')
  if (useCookie('session').value)
    await navigateTo('/loyalty');
  else
    await navigateTo('/login');
}

async function pollBatch() {
    try {
      console.log('polling')
        const res = await $fetch('/api/w/batch', {
            method: 'POST',
            headers: {
                'X-Brownie-Branch-ID': branches.SelectedBranchId,
                //'Etag': stampUuid.value// (rewardSelected && selectedCard.value.rewardAvailable) ? `${stampUuid.value}:r` : stampUuid.value
            },
            //query: { t: Date.now() },
            body: {
              requests: [ { "entity": "branch/availability" } ]
            },
            timeout: 3000,
            credentials: 'include'
        })
        console.log('res')
        console.log(res)
        processBatchRes(res)
        
    } catch (error) {
        if (error.statusCode == 404) {
        
        } else if (error.statusCode == 410) {
         
        } else if (error.statusCode == 401) {
            //await navigateTo({ path: '/login' })
        } else {
         
        }
    }
}


function processBatchRes(res) {
  // let pollInt = res["pollInt"]
  // if (pollInt) {

  //   pollInterval.value != pollInt
  //   pollInterval.value = res["pollInt"]
  //   stopPollTimer()
  //   if (!pollTimerActive.value)
  //     startPollTimer()
  // }

  if (res["branch/availability"]) {
    let br = res["branch/availability"]
    if (br.isOpen == false) {
      sharedData.ShowBanner()
    } else {
      sharedData.HideBanner()
    }

    sharedData.SetIsOpen(br.isOpen)

    let accept = br.accept
    if (accept) {
      sharedData.SetDeliveryAvailable(accept['delivery'] == true)
      sharedData.SetInStoreAvailable(accept['in-store'] == true)
      sharedData.SetPreorderAvailable(accept['pickup'] == true)
    } 

    let hours = br.workingHours
    if (hours) {
      sharedData.workingHours = hours
    }

    let days = br.workingDays
    if (days) {
      sharedData.workingDays = days
    }
  }

  if (res["version"]) {
    let ver = res["version"]
    if (menuData.version != ver && ver && menuData.version) {
      console.log(`showing update banner ver ${ver} incoming ver ${menuData.version}`)
      sharedData.ShowUpdateBanner(true)
    }
       
  }


}




//sharedData.BannerShown = true

// function presentAuthModal() {
//   showAuthModal = true
// }


// function presentVerificationModal(res) {
//   otpRes.value = res
//   showOTPVerifyModal.value = true
// }

</script>

<template>
  <div class="flex flex-col place-content-center place-items-center w-screen">
    <!-- <VitePwaManifest /> -->
    <div class="max-w-6xl w-screen">     
    <ClientOnly>
    <VaAffix v-if="sharedData.BannerShown" :offset-top="0">
    <VaAppBar
    top
    color="#FF7F50"
    gradient
    >
    <div class="flex flex-col place-content-center place-items-center w-full">
      <h6 class="va-h6" style="text-align: center;">We're closed at the moment :( Our working hours are {{ sharedData.workingHours }}</h6> 
    </div>
    </VaAppBar>
  </VaAffix>
  </ClientOnly>

  <ClientOnly>
    <VaAffix v-if="sharedData.orderTrackingBanner && useCookie('session').value && orderStore.orderId" :offset-top="0">
    <VaAppBar
    top
    color="#FF7F50"
    @click="presentOrderDetailsModal"
    >
    <div class="flex flex-col place-content-center place-items-center w-full">
      <h6 class="va-h6" style="text-align: center;">Your order {{ orderStore.orderId }} has been placed</h6> 
      <!-- <span class="text-md" style="text-align: center;">Click to track</span>  -->
      <!-- <VaButton
      @click="presentOrderDetailsModal"
      >
        Track
      </VaButton> -->
    </div>
    </VaAppBar>
  </VaAffix>
  </ClientOnly>
    <NuxtPage ref="page" />
    <!-- <VaAppBar
    v-if="shared.CartButtonShown"
    bottom
    fixed
    hide-on-scroll
    color="transparent"
    >
    <div class="flex flex-col place-content-center place-items-center w-screen">
      <VaBadge
      overlap
      :text="cart.ItemsInCart"
      placement="top-start"
      style="--va-badge-text-wrapper-border-radius: 50%; --va-badge-font-size: 15px "
      >
      <VaButton class="mb-6" size="large" style="min-width: 150px;" icon="shopping_basket">
      Cart Total: {{ cart.Total }}
    </VaButton>
      </VaBadge>
    </div>
    </VaAppBar> -->


    <ClientOnly>
      <VaModal
        v-model="showOrderTrackModal"
        hide-default-actions
        :mobile-fullscreen="false"
        no-outside-dismiss
        zIndex="999"
        blur
      >
            <OrderDetailsModal
                :tracking="true"
                :isMobile="sharedData.screenIsMobile"
                @back="showOrderTrackModal = !showOrderTrackModal"
                @goToLogin="goToLogin"
            />
      </VaModal>
    </ClientOnly>
    <!-- <VaModal
        v-model="showAuthModal"
        hide-default-actions
        :mobile-fullscreen="false"
        close-button
        no-outside-dismiss
        blur
        :noPadding="true"
      >
        <LoginModal @closeLogin="showAuthModal = !showAuthModal" @otpSent="presentVerificationModal" />
      </VaModal>

      <VaModal
        v-model="showOTPVerifyModal"
        hide-default-actions
        :mobile-fullscreen="false"
        close-button
        no-outside-dismiss
        blur
      >
        <OTPVerifyModal :otpRes="otpRes" @backToLogin="showOTPVerifyModal = !showOTPVerifyModal; showAuthModal = !showAuthModal" @loginSuccessful="console.log('welcommme!')" />
      </VaModal> -->


  </div>
  </div>
</template>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

</style>
