import { useStorage } from '@vueuse/core'

const cartLocalStore = useStorage('CartContent', []);

export const useCartStore = defineStore('cartStore', {
    state: () => ({
      ItemsInCart: 0,
      Cart: [],
      Total: 0,
    }),
    actions: {
      addItemToCart(item) {
        this.Cart.push(item)
        cartLocalStore.value = this.Cart
      },
      updateTotalAndCount() {
        let total = 0
        let c = 0
        for (let item of this.Cart) {
            let subtotal = item.unitSubtotal * item.quantity
            item.subtotal = subtotal
            total += subtotal
            c += item.quantity
        }
        this.Total = total
        this.ItemsInCart = c
      },
      removeItemByIdx(idx) {
        this.Cart.splice(idx, 1)
        cartLocalStore.value = this.Cart
      },
      removeItemById(id) {
        for (let i = 0; i < this.Cart.length; i++) {
            if (this.Cart[i]['id'] == id) {
                this.Cart.splice(i, 1)    
                break
            }
        }
        cartLocalStore.value = this.Cart
      },
      clearCart() {
        this.Cart = []
        this.ItemsInCart = 0
        this.Total = 0
        cartLocalStore.value = []
      }

    }
  })